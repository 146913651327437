.hidden {
  display: none;
}

.show {
  display: flex;
}

#modal-container {
  position: fixed;
  top: 20%;
  left: 25%;
  width: 50%;
  height: 50%;
  background: white;
  text-align: center;
  box-shadow: 0px 0px 2px 1px black;
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.faded {
  opacity: 0.2;
}

#fade-button {
  position: fixed;
  top: 25%;
  right: 30%;
}
