#hand {
  display: flex;
  max-width: 35em;
}
.card {
  height: 6em;
  border: solid 1px #808080;
  border-radius: 5px;
  text-align: left;
  width: 7em;
  min-width: 7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.card-text {
  background-color: white;
  color: black;
  font-weight: bold;
}
.card-wrapper {
  overflow: hidden;
}
.card-wrapper:last-child,
.card-wrapper:hover {
  overflow: visible;
}
.unselected {
  margin-top: 30px;
}
.selected {
  margin-top: 0px;
  overflow: visible;
}
